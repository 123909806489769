/*
*
* Responsive units
*/

@include unit-responsive(20px, 30px);

//
// Unit Spacing 
//

.unit-spacing-xxs {
	@include unit-spacing(8px);
}

.unit-spacing-xs {
	@include unit-spacing(12px, 10px);
}

.unit-spacing-sm {
	@include unit-spacing(16px, 10px);
}

.unit-spacing-md {
	@include unit-spacing(24px, 20px);
}

.unit-spacing-lg {
	@include unit-spacing(30px, 20px);
}
