/*
*
* Footers
*/

//
// Footer Classic
// Box Footer
// Footer Social List
// Footer Modern
// Footer Corporate
// Footer Minimal
//


//
// Footer Variant 2
//

.footer-variant-2 {
	text-align: center;
	letter-spacing: .025em;

	.form-label,
	.form-input { text-align: center; }

	.rights {	font-size: 12px; }

	.rd-form {
		* + .button {	margin-top: 10px; }
	}

	* + .rd-form { margin-top: 25px; }
	* + .group-lg { margin-top: 30px; }
	* + .row { margin-top: 24px; }
	.footer-brand + p { margin-top: 20px; }


	* + .footer-contacts {
		margin-top: 22px;
		padding-top: 32px;
		border-top: 1px solid $gray-200;
	}

	// Medium ≥768px
	@include media-breakpoint-up(md) {
		text-align: left;
	}
}

.footer-variant-2-content {
	padding: 50px 0;

	// Medium ≥768px
	@include media-breakpoint-up(md) {
		padding-top: 65px;
		padding-bottom: 65px;
	}
}

.footer-variant-2-bottom-panel {
	padding: 30px 0;
}

// Footer Modern Contacts
.footer-contacts {
	text-align: left;
	font-size: 0;
	line-height: 0;

	.icon {
		vertical-align: middle;
		text-align: left;
		min-width: 21px;
		font-size: 24px;
		line-height: 24px;
		color: $secondary;
	}

	.fa-envelope {font-size: 20px;}
	.fa-clock-o {font-size: 22px;}

	.link-phone {
		font-size: 18px;
		line-height: 1.34;
		letter-spacing: .025em;
	}

	.link-location { max-width: 192px; }

	p {
		font-size: 14px;
		line-height: 1.72;
		letter-spacing: .025em;
	}

	a {
		display: inline-block;
		vertical-align: middle;
		font-size: 14px;
		line-height: 1.72;
		letter-spacing: .025em;
		@include link($gray-800, $primary);
	}

	li + li {	margin-top: 18px; }
}

* + .footer-contacts { margin-top: 28px; }

.footer-social-list-2 {
	text-align: center;
	
	.icon {
		width: 28px;
		height: 28px;
		font-size: 16px;
		line-height: 28px;
		border-radius: 50%;
	}

	a {

		&,
		&:focus,
		&:active {
			color: $gray-800;
			background: transparent;
		}

		&:hover {
			color: $secondary;
			background: $gray-24;
		}
	}
}

.footer-social-list-title {
	color: $gray-800;
}



//
// Footer Modern
//
.footer-modern {
	background: $gray-23;

	.footer-variant-2-bottom-panel {
		background: $gray-21;
	}
}