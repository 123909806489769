/*
*
* Dividers
*/

hr {
	margin-top: 0;
	margin-bottom: 0;
	border-top: $hr-border-width solid $hr-border-color;
}

.divider {
	font-size: 0;
	line-height: 0;
	height: 1px;
	width: 100%;
	background: $gray-3;
}

.divider-30 { margin: 30px 0; }
.divider-35 { margin: 35px 0; }
.divider-40 { margin: 40px 0; }

.divider + * { margin-top: 0; }



// Title decorations
.title-decoration-lines-left {
	position: relative;
	padding-left: 30px;
	line-height: 1.2;

	&::before {
		position: absolute;
		content: '';
		top: 8%;
		left: 0;
		height: 78%;
		width: 4px;
		background: $gray-200;
	}
}

.title-decoration-lines-bottom {
	position: relative;
	line-height: 1.2;

	span {
		display: block;
		font-size: 0;
		line-height: 0;
		margin-top: 20px;

		&::after {
			display: inline-block;
			content: '';
			bottom: 0;
			height: 4px;
			width: 70px;
			background: $primary;
		}
	}

	// Medium ≥576px
	@include media-breakpoint-up(sm) {
		span { margin-top: 30px; }
	}
}

.title-decoration-lines-left + p { margin-top: 8px; }
.title-decoration-lines-bottom + p { margin-top: 30px; }

// Text decorations
.text-decoration-lines {
	position: relative;
	overflow: hidden;
	width: 100%;
}

.text-decoration-lines-content {
	position: relative;
	display: inline-block;
	padding: 0 16px;
	font-size: 18px;
	letter-spacing: .1em;
	color: $gray-500;
	font-family: $font-family-sec;
	text-transform: uppercase;

	&::before,
	&::after {
		position: absolute;
		content: '';
		top: calc( 50% - 2px );
		height: 1px;
		width: 100vw;
		background: $gray-3;
	}

	&::before {
		left: 0;
		transform: translate3d(-100%, 0, 0);
	}

	&::after {
		right: 0;
		transform: translate3d(100%, 0, 0);
	}
}

* + .text-decoration-lines { margin-top: 26px; }
.text-decoration-lines + .rd-form { margin-top: 22px; }



// Title decorations
.title-decoration-lines {
	position: relative;
	overflow: hidden;
	width: 100%;
}

.title-decoration-lines-content {
	position: relative;
	display: inline-block;
	padding: 0 16px;
	letter-spacing: .075em;

	&::before,
	&::after {
		position: absolute;
		content: '';
		top: calc( 50% - 2px );
		height: 1px;
		width: 100vw;
		background: $gray-200;
	}

	&::before {
		left: 0;
		transform: translate3d(-100%, 0, 0);
	}

	&::after {
		right: 0;
		transform: translate3d(100%, 0, 0);
	}
}