/*
*
* Sections
*/

//
// Section Spacing
//

.section-xs {
	padding-top: 40px;
	padding-bottom: 40px;
}

.section-sm {
	padding: 30px 0;

	&.section-first { padding-top: 60px; }
	&.section-last { padding-bottom: 60px; }
}

.section-60,
.section-md,
.section-lg,
.section-xl {
	padding: 60px 0;
}

// Section with bottom zero padding
html [class*='section-'].section-bottom-0 {
	padding-bottom: 0;
}

// Section with top zero padding
html [class*='section-'].section-top-0 {
	padding-top: 0;
}

// Section zero padding
html [class*='section-'] {
	// Large ≥992px
	@include media-breakpoint-up(lg) {
		&.section-lg-0 { padding: 0; }
	}
}

@include media-breakpoint-up(md) {

	.section-sm {
		padding: 40px 0;

		&.section-first { padding-top: 80px; }
		&.section-last { padding-bottom: 80px; }
	}

	.section-md {
		padding: 70px 0;
	}

	.section-lg {
		padding: 80px 0;
	}

	.section-xl {
		padding: 90px 0;
	}
}

@include media-breakpoint-up(lg) {

	.section-sm {
		padding: 50px 0;

		&.section-first { padding-top: 100px; }
		&.section-last { padding-bottom: 100px; }
	}

	.section-md {
		padding: 80px 0;
	}

	.section-lg {
		padding: 90px 0;
	}

	.section-inset-custom-6,
	.section-xl {
		padding: 100px 0;
	}

	.desktop {
		.section-top-1 { padding-top: 0; }
	}
}

// Mega large ≥1600px
@include media-breakpoint-up(xxl) {
  .section-inset-custom-1 {
		padding-top: 120px;
		padding-bottom: 160px;
	}

	.section-inset-custom-2 {
		padding-top: 90px;
		padding-bottom: 130px;
	}

	.section-inset-custom-3 {
		padding-top: 100px;
		padding-bottom: 130px;
	}

	.section-inset-custom-4 {
		padding-top: 125px;
		padding-bottom: 140px;
	}

	.section-inset-custom-5 {
		padding-top: 135px;
		padding-bottom: 135px;
	}
}

//
// Custom sections
//

// Mega large ≥1600px
@include media-breakpoint-up(xxl) {
	.section-fluid {
		padding-left: 75px;
		padding-right: 75px;

		.container-fluid {
			padding-left: 0;
			padding-right: 0;
			max-width: 1770px;
			margin-left: auto;
			margin-right: auto;
		}
	}
}

// Section single
.section-single {
	position: relative;
	overflow: hidden;
	display: flex;
	text-align: center;

	p {
		margin-left: auto;
		margin-right: auto;
	}

	.rights {
		letter-spacing: 0;
		font-weight: 400;

		a {
			position: relative;
			border-bottom: 1px solid $white
		}
	}

	.rd-mailform-wrap {
		max-width: 670px;
		margin-left: auto;
		margin-right: auto;
		text-align: center;
	}

	* + p {
		margin-top: 6px;
	}
	p + p {
		margin-top: 0;
	}
	.DateCountdown + p {
		margin-top: 60px;
	}
	* + .button {
		margin-top: 40px;
	}
	* + .rights {
		margin-top: 35px;
	}
	* + .countdown-wrap {
		margin-top: 35px;
	}
	* + .rd-mailform-wrap {
		margin-top: 20px;
	}
	.countdown-wrap + * {
		margin-top: 35px;
	}

	@include media-breakpoint-up(lg) {
		* + .button {
			margin-top: 60px;
		}
		* + .rights {
			margin-top: 60px;
		}
		.countdown-wrap + * {
			margin-top: 35px;
		}
	}
}

.section-404 {
	// Extra large ≥1200px
	@include media-breakpoint-up(xl) {
		* + .button {
			margin-top: 90px;
		}
	}
}

.section-single-header {
	padding: calc(1em + 4vh) 0 calc(1em + 2vh);
}

.section-single-main {
	padding: 60px 0 calc(1em + 5vh);
}

.section-single-footer {
	padding: calc(1em + 2vh) 0 calc(1em + 4vh);
}

.section-single-inner {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	min-height: 100vh;

	> * {
		width: 100%;
	}
}



@include media-breakpoint-up(md) {
	.section-single-main {
		padding: 70px 0 30px;
	}
}

@include media-breakpoint-up(xl) {
	.section-single-header {
		padding-top: calc(1em + 9vh);
	}
}

// Mega large ≥1600px
@include media-breakpoint-up(xxl) {
	.section-single-main {
		padding-top: 65px;
		padding-bottom: 100px;
	}
}