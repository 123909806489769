/**
*
* Card
*/

//
// Table of Contents:
//
// Form Card
//

//
// Form Card
//



.card-form {
  background: $white;
  transition: all 200ms ease;

  * + .form-wrap { margin-top: 14px; }
}

* + .card-form { margin-top: 26px; }

.card-login-register {
  margin-left: auto;
  margin-right: auto;
  max-width: 400px;
}

.card-top-panel {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.card-form-login {
  position: relative;
  top: 0;
  width: 100%;
  z-index: 1;
}

.card-form-register {
  position: relative;
  display: none;
  z-index: 1;
}

.card-toggle {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 43px;
  height: 17px;
  background: $gray-4;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.card-toggle-circle {
  position: absolute;
  top: 50%;
  left: -4px;
  width: 26px;
  height: 26px;
  background: $white;
  border-radius: 50%;
  border: 1px solid $gray-5;
  transform: translateY(-50%);
  will-change: transform;
  transition: all .1s linear;

  &::before {
    position: absolute;
    content: '';
    top: 50%;
    left: 50%;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: $primary;
    transform: translate(-50%, -50%);
    transition: all .2s ease-in-out;
  }
}

.card-subtitle,
.card-top-panel-left {
  position: relative;
}

.card-title {
  margin-bottom: 0;
  letter-spacing: .05em;
  transition: all 300ms ease;
}

.card-subtitle {
  top: 2px;
  text-align: right;
  margin-right: 8px;
  font-size: 18px;
  letter-spacing: .1em;
  font-family: $font-family-sec;
  color: $gray-500;
  text-transform: uppercase;
  transition: all 300ms ease;

  > * { display: inline-block; }
}

.card-subtitle-login,
.card-title-login {
  opacity: 1;
  visibility: visible;
}

.card-subtitle-register,
.card-title-register {
  position: absolute;
  top: 50%;
  opacity: 0;
  visibility: hidden;
  transform: translateY(-50%);
  will-change: transform;
}

.card-title-register {
  left: 0;
}

.card-subtitle-register {
  right: 0;
}

.card-toggle-circle:hover {

  &::before {
    width: 16px;
    height: 16px;
  }
}

.card-toggle.active {

  .card-toggle-circle {
    transform: translate(112%, -50%);
  }
}

.card-login-register.active {

  .card-form-login {
    display: none;
  }

  .card-form-register {
    display: block;
  }

  .card-subtitle-login,
  .card-title-login {
    opacity: 0;
    visibility: hidden;
  }

  .card-subtitle-register,
  .card-title-register {
    opacity: 1;
    visibility: visible;
  }
}

// Small <576px
@include media-breakpoint-down(xs) {
  .card-login-register {
    .button-lg {
      padding-left: 20px;
      padding-right: 20px;
    }
  }

  .card-top-panel-right {
    text-align: right;
    width: calc( 50% + 50px );
    margin-top: 4px;
  }

  .card-title-register {
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

// Medium ≥576px
@include media-breakpoint-up(sm) {

  .card-top-panel {
    flex-direction: row;
  }

  .card-login-register {
    max-width: 100%;
  }
}